import {
    TARGET_PRICE_TYPE,
    SUBJECT_TYPE,
    LISTING_CLASSES,
    LISTING_STYLES,
    LISTING_GRID_WIDTH,
    LISTING_PREMIUM_TYPE,
    LISTINGS_CONTAINER_ID,
    LISTINGS_MOBILE_BREAKPOINT
} from "../../modules/members/listings/create/js/constants";
import * as Mustache from "mustache";
import * as moment from 'moment-timezone';
import * as Solr from "../js/Solr";
import 'chosen-js/chosen.jquery';
import {
    guid,
    url,
    slugify,
    addEllipsis,
    getListingUrl,
    getItemShippingCountry,
    getPriceHTML,
    getQuantityHTML,
    getPriceQuantityHTML,
    clean,
    getRepliesLabelHTML
} from "../utils";
import {AjaxDataLoad} from "../../modules/shared/components/ajax-data-load/ajax-data-load";


export const Listings = (function() {
    const exports = {};

    exports.data = {
        defaultStyle: LISTING_STYLES.grid,
    };
    exports.templates = {
        listing_template: null,
        company_template: null
    };
    exports.$container = $('#' + LISTINGS_CONTAINER_ID);
    exports.isMembers = false;
    exports.listingStyle = exports.data.defaultStyle;
    exports.listingsData = [];
    exports.title = window.listingsBoxTitle ? window.listingsBoxTitle : 'Listings';
    exports.idsToLoad = {};

    var itemsPerPage = 50;
    if (typeof _itemsPerPage !== 'undefined') {
        itemsPerPage = _itemsPerPage;
    }
    exports.props = {
        rowsPerPage: itemsPerPage
    };

    function clearContainer(container) {
        container.html('');
    }


    function test(){

    }

    // TODO: refactor to hide/show element
    function __showLoading(container, loadNum = 20) {
        var loading_template = document.getElementById('listings-skeleton-template');
        container.html('<div class="listings-loading clearfix item-loading"></div>');

        for (let i = 0; i < loadNum; i++) {
            container.find('.item-loading').append(loading_template.content.cloneNode(true));
        }

        if (Listings.listingStyle !== LISTING_STYLES.full) {
            container.find('.item-loading .listing--skeleton')
                .removeClass(LISTING_CLASSES.full)
                .addClass(LISTING_CLASSES[Listings.listingStyle]);
        }
    }

    function __hideLoading(container) {
        container.find('.item-loading').remove();
        container.find('.listing--skeleton').remove(); // not sure we need this one
    }

    // TODO: refactor to hide/show element
    function __showNoItemsMessage(container, message) {
        container.append('' +
            '<div class="listings-empty no-items">' +
            '   <span class="listings-empty__text">' + message + '</span>' +
            '</div>');
    }

    function __hideNoItemsMessage(container) {
        container.find('.no-items').remove();
    }

    function __drawNoItemsMessage(container) {
        if ($('div#storefront').length > 0) {
            __showNoItemsMessage(container, 'No recent buys or sells posted.');
        } else if ($('.all_listing_page').length > 0) {
            __showNoItemsMessage(container, 'No current want-to-buys or want-to-sells found.');
        } else {
            __showNoItemsMessage(container, 'Sorry, no listings match your search. Try changing your search term, or try using different filters on the left.');
        }
    }

    function __showLayoutButtons() {
        Listings.$container.find('.listing-subheader').css('opacity', '1');
        Listings.$container.find('.toggle-view-button').attr('aria-disabled', 'false');
    }

    function __hideLayoutButtons() {
        Listings.$container.find('.listing-subheader').css('opacity', '0');
        Listings.$container.find('.toggle-view-button').attr('aria-disabled', 'true');
    }

    function __showErrorMessage(container) {
        __hideLoading(container);
        container.append('' +
            '<div class="listings-empty no-items">' +
            '   <span class="listings-empty__text">An error occurred. Please, try again later.</span>' +
            '</div>');
    }

    function __addSubtitle() {
        Listings.$container.prepend('' +
            '<div class="listing-subheader tooltip-wrapper--yellow" style="opacity: 0;">' +
            '    <div class="listings__header__sort">' +
            '       <span>Sort by</span>' +
            '       <select name="listing_sort" id="listing_sort" class="chosen-link">' +
            '           <option value="prom">Relevance</option>' +
            '           <option value="time">Time</option>' +
            '       </select>' +
            '    </div>' +
            '    <div class="listings__header__actions tl-effect-promo">' +
            '        <button type="button" class="toggle-view-button toggle-full" data-toggle-style="full" title="Fullwidth View" aria-label="Fullwidth View" aria-disabled="true">' +
            '            <span class="fas fa-th-list no-click" aria-hidden="true"></span>' +
            '        </button>' +
            '        <button type="button" class="toggle-view-button toggle-line" data-toggle-style="line" title="Line View" aria-label="Line View" aria-disabled="true">' +
            '            <span class="fas fa-list-ul no-click" aria-hidden="true"></span>' +
            '        </button>' +
            '        <button type="button" class="toggle-view-button toggle-grid" data-toggle-style="grid" title="Grid View" aria-label="Grid View" aria-disabled="true">' +
            '            <span class="fas fa-th no-click" aria-hidden="true"></span>' +
            '        </button>' +
            '    </div>' +
            '</div>' +
            '<h2 class="listings__title">' + Listings.title + '</h2>' +
            '<div class="clearfix"></div>'
        );

        $('select[name=listing_sort]').chosen({
            disable_search: true,
            inherit_select_classes: true,
        });
    }

    exports.setListingsSort = function (sort, doExtraCall) {
        try {
            localStorage.setItem('listing-sort', sort);
        } catch (e) {}

        if (typeof doExtraCall == 'function') {
            doExtraCall();
        }
    };

    exports.getListingsSort = function () {
        var defaultSort = 'prom';
        var listingSort = '';
        try {

            listingSort = localStorage.getItem('listing-sort');

            if(!listingSort){
                listingSort = defaultSort;
                Listings.setListingsSort(listingSort);
            }

        } catch (e) {
            listingSort = defaultSort;
        }

        $('select[name=listing_sort]').val(listingSort).trigger('chosen:updated');

        return listingSort;
    };

    exports.init = function(showTitle = true){
        try {
            const listingStyle = localStorage.getItem('listing-style');
            if (listingStyle && LISTING_STYLES.hasOwnProperty(listingStyle)) {
                Listings.setListingStyle(listingStyle, $('button.toggle-' + listingStyle)[0], false);
            } else {
                Listings.setListingStyle(exports.data.defaultStyle, $('button.toggle-' + exports.data.defaultStyle)[0], false);
            }
        } catch (e) {
            Listings.setListingStyle(exports.data.defaultStyle, $('button.toggle-' + exports.data.defaultStyle)[0], false);
        }

        if(showTitle){
            __addSubtitle();
        }
        
        $('button.toggle-' + Listings.listingStyle).addClass('active');

        $(document).on('click', '.listing__label, .listing__label span', event => {
            $(event.target).parents('.listing').find('.listing__company').toggle();
            $(event.target).parents('.listing').find('.listing__label').toggleClass('listing__label--opened');
            event.stopPropagation();
        });

        document.querySelectorAll('[data-toggle-style]').forEach((element) => {
            element.addEventListener('click', event => {
                Listings.setListingStyle(event.target.getAttribute('data-toggle-style'), event.target, true);

            });
        });

        $(document).on('change', 'select[name=listing_sort]', event => {
            Listings.setListingsSort(event.target.value, function(){
                Listings.getListings();
            });
        });

        Listings.getListingsSort();

        $(window).resize(function() {
            Listings.__switchResponsiveLayout();

            if ($('.tl-effect-promo--active').length > 0) {
                // TODO: fix tooltip blinking
                $('.listings__header__actions').tooltip('show');
            }
        });

        document.addEventListener('listings.get', (event) => {
            if (event.hasOwnProperty('detail') && event.detail.callback !== undefined) {
                Listings.getListings(event.detail.callback);
            } else {
                Listings.getListings();
            }
        });
    };

    exports.shouldShowMobileListings = function ($listingsContainer) {
        return $listingsContainer.width() <= LISTINGS_MOBILE_BREAKPOINT;
    };

    exports.__getTemplate = function(type) {
        if (Listings.templates[type] !== null) {
            return Listings.templates[type];
        } else {
            var template = $('#' + type).html();
            Mustache.parse(template);
            Listings.templates[type] = template;
            return Listings.templates[type];
        }
    };

    exports.__switchResponsiveLayout = function() {
        var container = Listings.$container.find('.item-list');
        container.find('.listing').toggleClass('listing--desktop', !Listings.shouldShowMobileListings(container));
        // Listings.__setListingGridWidth(container);
    };

    // exports.__setListingGridWidth = function($container) {
    //     if ($container.find('.listing--grid').length > 0) {
    //         var width = $container.width();
    //         var factor = Math.floor(width / LISTING_GRID_WIDTH);
    //         $container.find('.listing--grid').css('width', 'calc((100% / ' + factor + ') - 10px)');
    //     }
    // }

    exports.setListingStyle = function (style, obj, doEvent = true) {
        const oldStyleClass = LISTING_CLASSES[Listings.listingStyle];
        const newStyleClass = LISTING_CLASSES[style];

        const container = Listings.$container.find('.item-list');
        Listings.listingStyle = style;

        try {
            localStorage.setItem('listing-style', style);
        } catch(e) {
        }

        if (doEvent) {
            window.Analytics.track('changed-listing-style',{
                type_id: 45,
                eventData: {
                    "event_cat_id": 5,
                    "event_cat_name": "3. Browse Listings",
                    "tl_event_id": 45,
                    "tl_event_name":"Browsed Listings",
                    "tl_event_data":style
                },
                localData: {
                    datavar: style
                }
            });
        }
        
        _data.filters.page = 1;

        $(obj).parent().find('.active').removeClass('active');
        $(obj).addClass('active');

        container.find('.listing')
            .removeClass(oldStyleClass)
            .addClass(newStyleClass)
            .css('width', '');

        // hiding company line when switching to another layout
        $('.listing__company[style]').each((index, item) => $(item).removeAttr('style'));
        $('.listing__label').each((index, item) => $(item).removeClass('listing__label--opened'));

        // if (Listings.listingStyle === LISTING_STYLES.grid) {
        //     Listings.__setListingGridWidth(container);
        // }
    };

    exports._showBecomeMemberInfo = function(container) {
        container.append('' +
            '<li class="i-created-message text-center tl-highlighted-light">' +
            '   <strong class="fs-16">To create a new listing, please ' +
            '       <a href="/w/membership/?uppath=php-home/create-new-listing-message/bold-orange-link/become-a-member">become a member.</a>' +
            '   </strong>' +
            '</li>');
    };

    exports._drawListings = function(listingData) {
        var itemsPerPage = 50;
        if (typeof _itemsPerPage !== 'undefined') {
            itemsPerPage = _itemsPerPage;
        }
        _data.rows = itemsPerPage;
        exports.idsToLoad = {};

        const login_userid = $('#login_userid').val();
        const viewing_userid = $('#viewing_userid').val();
        const container = Listings.$container.find('.item-list');

        if (_data.filters.hasOwnProperty('created_by')) {
            if (_data.filters.page === 1) {
                if (login_userid === viewing_userid) {
                    Listings._showBecomeMemberInfo(container);
                }
            }
        }

        if (listingData.length > 0) {

            if (listingData.length < itemsPerPage) {
                if (_data.paginate) {
                    if (_data.filters.page > 1) {
                        $('.pagination-container').show();
                        $('.load-more').hide();
                        _data.generate_page_links();
                    } else {
                        $('.pagination-container').hide();
                    }
                } else {
                    $('.pagination-container').hide();
                }
            } else {
                if (_data.paginate) {
                    $('.pagination-container').show();
                    $('.load-more').hide();
                    _data.generate_page_links();
                } else {
                    $('.pagination-container').show();
                    $('.load-more').show();
                    exports.$container.find('.pagination').remove();
                }
            }

            // container.empty();
            __hideLoading(container);
            __showLayoutButtons();

            $.each(listingData, function(i, item) {
                if (_data._show_member_listings) {
                    Listings.appendItem(item);
                } else {
                    const previousWasMembersOnly = i > 0 && !listingData[i-1].displays_to_non_members;
                    const currentIsMembersOnly = !item.displays_to_non_members;
                    if (!previousWasMembersOnly || previousWasMembersOnly && !currentIsMembersOnly) {
                        Listings.appendItem(item);
                    }
                }
            });

            // if (Listings.listingStyle === LISTING_STYLES.grid) {
            //     Listings.__setListingGridWidth(container);
            // }

            if (is_logged_in && _data._show_member_listings && _data.use_display_name_popovers) {
                Listings.__loadPopoverInfo();
            }

            setTimeout(function() {

                if (_data.filters.page > 1) {
                    return;
                }

                var itemCount = $('.item-list li').length;

                window.Analytics.track('5|3. Browse Listings',{
                    type_id: 45,
                    eventData: {
                        "event_cat_id": 5,
                        "event_cat_name": "3. Browse Listings",
                        "tl_event_id": 45,
                        "tl_event_name":"Browsed Listings",
                        "tl_event_data":'displayed more listings',
                        item_count: itemCount
                    },
                    localData: {
                        datavar: 'displayed more listings',
                        datanum: itemCount
                    }
                });
                /*
                framework.post(url('api/log_generic_event'), {
                    _token: $('input[name="_token"]:first').val(),
                    'type_id': 45,
                    'datavar': 'displayed more listings',
                    'datanum': itemCount
                });
                */
                __hideNoItemsMessage(container);

                if (itemCount === 0) {
                    __drawNoItemsMessage(container);
                }
            }, 2000);

        } else {
            __hideLoading(container);
            __hideNoItemsMessage(container);

            $('.load-more').hide();

            if (container.find('.listing').length === 0) {
                __drawNoItemsMessage(container);
            }
        }
    }

    exports.__loadPopoverInfo = function() {
        // console.debug(Object.keys(exports.idsToLoad));

        for (let id in exports.idsToLoad) {
            if (exports.idsToLoad.hasOwnProperty(id)) {
                exports.idsToLoad[id].forEach(function(typeId) {
                    const $userLink = $('a[data-usid="' + id + '"][data-subj-type="' + typeId + '"]');

                    const postData = AjaxDataLoad.extendDataLoad({
                        "usid":             id,
                        "subject_typeid":   typeId,
                        "_token":           $('input[name="_token"]:first').val(),
                    });

                    framework.get(url('api/display_name_pop'), postData,function (rudsp) {
                        var popPannel = '';
                        var typCode = rudsp.typCode;
                        var memLabel = '';
                        var memDate = '';
                        var certMem = '';


                        if (parseInt(typCode) === 1000) {
                            $userLink.text(memLabel.replace(/\s+/g, '-').toLowerCase());
                            $userLink.removeAttr('data-toggle data-usid data-subj-type');
                            $userLink.click(function(){
                                tradeloop.modal('member_content_gate_wtb_display_name');
                            });
                            return;
                        }

                        if (rudsp.pop) {
                            rudsp.pop.forEach(function (p) {
                                memLabel = p.label;
                                certMem = '<span class="tl-color--any-member">' + memLabel + '</span>';

                                if (p.is_user_certified) {
                                    $('img[data-cert-usid="' + id + '"').show();

                                    const certMemImage = '<img width="16" height="16" src="/images/tl-cert/tradeloop-cert-small-1.png" style="vertical-align:-3px;">';
                                    certMem = certMemImage + ' <span class="tl-color--certified-member">' + memLabel + '</span>';
                                }

                                if (p.member_since && parseInt(p.non_member) === 0) {
                                    memDate = '<small>Since ' + moment(p.member_since).format('YYYY') + '</small>';
                                }

                                popPannel = '' +
                                    '<div class="row">' +
                                    '<div class="pull-left w-120">' +
                                    '<div class="profile-box__user-image" style="width: 100px;height: 100px;">' +
                                    '<img src="' + p.user_logo + '" class="profile-box__user-thumb" alt="' + p.user_name + '">' +
                                    '</div>' +
                                    '</div>' +
                                    '<div class="pull-left w-less-120">' +
                                    '<h2 class="margin-bottom-0"><a href="/users/' + p.users_uuid + '">' + p.user_name + '</a></h2>' +
                                    '<div class="margin-top-1"><a class="black-bold" href="/companies/' + p.company_uuid + '">' + p.company_name + '</a></div>' +
                                    '<div class="margin-top-10"><b><a href="' + tradeloop.cfMembersSite + '/companies/certification_report/?company=' + p.companyid + '">Due Diligence Report</a></b></div>' +
                                    '<div class="margin-top-1">' + certMem + '&nbsp;&nbsp;' + memDate + '</div>' +
                                    '</div>' +
                                    '</div>';
                            });

                            $userLink.each((index, item) => {
                                const $item = $(item);

                                if ($item.data('bs.popover') === undefined) {
                                    $item.popover({
                                        title: '',
                                        content: popPannel,
                                        placement: 'top',
                                        html: true,
                                        container: '.main_content',
                                        trigger: "click",
                                        template: '<div class="popover popover-dsp-name"><div class="arrow"></div><div class="popover-content"></div></div>'
                                    });

                                    $item.on('shown.bs.popover', function (e) {
                                        setTimeout(function () {
                                            window.Analytics.track('17|3b. Viewed Listing/Profile',{
                                                type_id: 198,
                                                eventData: {
                                                    "event_cat_id": 17,
                                                    "event_cat_name": "3b. Viewed Listing/Profile",
                                                    "tl_event_id": 198,
                                                    "tl_event_name":"Viewed Display Name Popup",
                                                },
                                                localData: {
                                                    via_userid: id
                                                }
                                            });
                                            /*
                                            framework.post(url('api/log_generic_event'), {
                                                _token: tlpxtoken,
                                                'type_id': 198,
                                                'via_userid': render_data.userId
                                            });
                                            */
                                        }, 100);
                                    });
                                }
                            });
                        }
                    });
                });
            }
        }

    }

    exports.__addCompanyInfo = function(render_data, subjectTypeId, callback) {
        var dsp_name_data = {
            "usid": render_data.userId,
            "subject_typeid": subjectTypeId,
            "tlpxtoken": window.tlpxtoken,
            "x-sender": "js",
            "_token": $('input[name="_token"]:first').val(),
        };

        framework.get(url('api/sponsored_listings_pop'), dsp_name_data, function(rudsp) {
            if (rudsp.pop) {
                rudsp.pop.forEach(function(p) {
                    render_data.company_logo = p.company_logo;
                    render_data.companyLink = tradeloop.cfMembersSite + '/companies/full_profile/?company=' + p.companyid;
                    render_data.companyProfileLink = '/companies/' + p.company_uuid;
                    render_data.premium_label = p.label;
                    render_data.premium_label_class = p.is_user_certified ? 'tl-color--certified-member' : 'tl-color--any-member';
                    render_data.city = p.city;
                    render_data.country = p.country;
                    render_data.state = p.state;
                    render_data.isUSA = render_data.country === 'United States';
                    render_data.is_user_certified = p.is_user_certified;
                    render_data.member_since = moment(p.member_since).format('YYYY');
                    render_data.companyName = p.company_name;
                });
            }

            if (rudsp.credentials && rudsp.credentials.length > 0) {
                rudsp.credentials.forEach(function(credential, i) {
                    if (i <= 5) {
                        render_data.credentials.push({
                            logo: credential.logo_url_path,
                            name: credential.full_name,
                            acronym: credential.acronym
                        });
                    }
                });
            }

            if (render_data.credentials.length > 0) {
                render_data.hasCredentials = true;
            }

            if (typeof callback === 'function') {
                callback(render_data);
            }
        });
    };

    exports.__sortByPremiumUser = function(items) {
        return items.sort((a, b) => {
            if(!a.keep_on_top) {
                return 0;
            }
            if(!b.keep_on_top) {
                return 1;
            }
            if(a.companyid > b.companyid) {
                return 1;
            } else if (a.companyid < b.companyid) {
                return -1;
            }

            return 0;
        })
    };

    exports.__getListingClasses = function(renderData) {
        var result = [];

        if (renderData.self) {
            result.push('listing--self');
        }

        if (renderData.isWTB) {
            result.push('listing--wtb');
        } else {
            result.push('listing--wts');
        }

        if (!renderData.isMobile) {
            result.push('listing--desktop');
        }

        if (renderData.isPremium) {
            result.push('listing--premium');
        }

        if (renderData.isSponsored) {
            result.push('listing--sponsored');
        }

        if (renderData.isSponsoredKeepOnTop) {
            result.push('listing--sponsored listing--sponsored-top');
        }

        if (renderData.isFull) {
            result.push(LISTING_CLASSES.full);
        }

        if (renderData.isLine) {
            result.push(LISTING_CLASSES.line);
        }

        if (renderData.isGrid) {
            result.push(LISTING_CLASSES.grid);
        }

        if (renderData.isMemberListing) {
            result.push('listing--members');
        }

        if (renderData.hasStatusLabel) {
            result.push('listing--has-status-label');
        }

        if (renderData.isMembersOnly) {
            result.push('listing--members-only');
        }

        if (!renderData.hasOriginalImage) {
            result.push('listing--has-default-image');
        }

        return result.join(' ');
    };

    exports.parseSearchTerms = function(){
        return new Promise((resolve, reject)=>{
            if(!_data.filters.hasOwnProperty('search')) {
                resolve();
                return;
            }
            var qsearch = "";
            qsearch = _data.filters.search.toLowerCase().replace(/\//gi, ' ');
            if (qsearch.length > 2 && qsearch[0] === '"' && qsearch[qsearch.length - 1] == '"') {
                resolve();
                return;
            }
            var words = qsearch.split(' ');
            Solr.Model.where(Solr.AnyOf.make('manufacturer_name',words))
                .groupBy('manufacturer_name')
                .get(100)
                .then((response)=>{
                    response.grouped.manufacturer_name.groups.forEach((group)=>{
                        var groupValue = group.groupValue;
                        if(group.doclist.docs.length > 0) {
                            if (!_data.filters.hasOwnProperty('manufacturer_ids')) {
                                _data.filters.manufacturer_ids = [];
                            }
                            if (!_data.filters.manufacturer_ids.includes(group.doclist.docs[0].manu_id)) {
                                _data.filters.manufacturer_ids.push(group.doclist.docs[0].manu_id);
                            }
                            if (_data.filters.manufacturer_ids.length === 1) {
                                _data.filters.manufacturer_name = group.doclist.docs[0].manufacturer_name;
                            } else {
                                delete _data.filters.manufacturer_name;
                            }

                        }
                    });
                    _data.dispatchPopulateFiltersEvent();
                    resolve();
                })

        });
    }

    exports.getListings = function(callback, listingsLimit) {

        var container = Listings.$container.find('.item-list');
        _data.numFound = 0;

        $('.pagination-container').hide();
        __hideNoItemsMessage(container);

        if (_data.paginate || _data.filters.page === 1) {
            if (listingsLimit !== undefined && listingsLimit > 0) {
                __showLoading(container, listingsLimit);
            } else {
                __showLoading(container);
            }

            __hideLayoutButtons();
            Listings.listingsData = [];
        }

        //Listings.parseSearchTerms();


        var fstr = _data.parseFilters();
        var qtr = _data.parseQueryFilters();
        var sort = [];
        var fields = ['*'];
        var listingsSortingBy = Listings.getListingsSort();


        /**
         * score => Solr Relevance Score based how closely a search matches or how recent the listing is.
         * sorting_id => Score stored in the database
         */

        var now = Math.floor(Date.now() / 1000);
        var exp = 'max(sorting_id,sub(70,div(sub('+now+', sorting_datetime),360)))'


        if (_data.filters.hasOwnProperty('search') && _data.filters.search !== '') {

            switch(listingsSortingBy){
                case 'time':
                    //Search is not empty and sorting is by time.
                    sort = ['is_expired:ASC', 'keep_on_top:DESC', 'sorting_datetime:DESC'];
                    break;
                default:
                    //Search is not empty and sorting is by relevance
                    sort = ['is_expired:ASC', 'keep_on_top:DESC', 'score:DESC', exp+':DESC', 'sorting_id:DESC', 'sorting_datetime:DESC'];
                    break;
            }

            fields.push('score');
        } else {
            switch(listingsSortingBy){
                case 'time':
                    //Search is empty and sorting is by time
                    sort = ['is_expired:ASC', 'keep_on_top:DESC', 'sorting_datetime:DESC', exp+':DESC'];
                    break;
                default:
                    //Search is empty and sorting is by relevance.  In this case, the Solr Relevance score looks at whether the listing is recent.
                    sort = ['is_expired:ASC', 'keep_on_top:DESC', exp+':DESC', 'sorting_id:DESC', 'sorting_datetime:DESC'];
                    break;
            }

            //fields.push('score');
        }



        let itemsPerPage = 50;
        if (typeof _itemsPerPage !== 'undefined') {
            itemsPerPage = _itemsPerPage;
        }

        var query = Solr.Model.searchRaw(qtr);

        if(fstr) {
            fstr.forEach((str)=>{
                query.whereRaw(str);
            });
        }
        if(sort) {
            sort.forEach((s)=>{
                var sortParams = s.split(':');
                query.orderBy(sortParams[0],sortParams[1]);
            });
        }
        query.get(_data.rows, _data.filters.page)
            .then((r)=>{
                _data.numFound = r.response.numFound;
                Listings.listingsData = Listings.listingsData.concat(r.response.docs);
                Listings._drawListings(r.response.docs);

                if (_data.filters.hasOwnProperty('search') && _data.filters.search !== '') {
                    window.Analytics.trackInGA("32|3a. Search", {
                        "event_cat_id": 32,
                        "event_cat_name": "3a. Search",
                        "tl_event_id": 48,
                        "tl_event_name":"Searched Bcasts/Listings",
                        "searchTerm":_data.filters.search
                    });
                }
                window.Analytics.trackInGA("5|3. Browse Listings", {
                    "event_cat_id": 5,
                    "event_cat_name": "3. Browse Listings",
                    "tl_event_id": 45,
                    "tl_event_name":"Browsed Listings",
                })
            },()=>{
                __showErrorMessage(container);
            });
        /*
        framework.get(url("api/search/listings"), {
            search: qtr,
            start: ((_data.filters.page - 1) * itemsPerPage),
            rows: _data.rows,
            filters: fstr,
            sort: sort
        }, function(r) {
            _data.numFound = r.response.numFound;
            Listings.listingsData = Listings.listingsData.concat(r.response.docs);
            Listings._drawListings(r.response.docs);

            if (_data.filters.hasOwnProperty('search') && _data.filters.search !== '') {
                window.Analytics.trackInGA("32|3a. Search", {
                    "event_cat_id": 32,
                    "event_cat_name": "3a. Search",
                    "tl_event_id": 48,
                    "tl_event_name":"Searched Bcasts/Listings",
                    "searchTerm":_data.filters.search
                });
            }
            window.Analytics.trackInGA("5|3. Browse Listings", {
                "event_cat_id": 5,
                "event_cat_name": "3. Browse Listings",
                "tl_event_id": 45,
                "tl_event_name":"Browsed Listings",
            })

        }, function(error, data) {
            __showErrorMessage(container);
        }, {
            xhrFields: {
                withCredentials: true
            },
            beforeSend: function(xhr, o) {
            }
        });
        */
        framework.post(url('api/store_filters'), {
            _token: $('input[name="_token"]:first').val(),
            filters: JSON.stringify(_data.filters)
        }, function(r) {
            _data.get_filters();
            if (typeof callback == 'function') {
                callback();
            }
        });
    }

    exports._parseItem = function(item) {
        var shouldAppend = true;
        var replied = _data.hasReplied(item.tradefeed_id);
        var replyCount = _data.replyCount(item.tradefeed_id);

        if (_data.filters.hasOwnProperty('replied_by')) {
            if (replied) {
                shouldAppend = true;
            } else {
                shouldAppend = false;
            }
        }

        if (item.isSponsoredKeepOnTop && item.expired) {
            shouldAppend = false;
        }

        return {
            shouldAppend: shouldAppend,
            hasReplied: replied,
            replyCount: replyCount
        };
    }

    exports.appendSingleItem = function(item) {
        var container = Listings.$container.find('.item-list');
         __showLoading(container, 1);

         setTimeout(function() {
            Listings.appendItem(item, function(){
                __hideLoading(container);
            });
        }, 1500); 
    }

    exports.appendItem = function(item, callback) {
        var parsedItem = Listings._parseItem(item);

        if (!parsedItem.shouldAppend) {
            return;
        }

        const container = Listings.$container.find('.item-list');
        const login_userid = $('#login_userid').val();
        const viewing_userid = $('#viewing_userid').val();
        let cat_tree = "";
        const gid = guid().trim();
        let slug_subject_type = "";
        let modal_slug_string = "";
        let series_slug_string = "";
        let found_brand_series_model_slug = 0; // this var if brand or series or model slug exist
        let found_series_slug_in_model_slug = 0; // this var if series and model slug name same
        let found_brand_slug_in_series_slug = 0; // this var if brand and series slug name same
        const messageSubject = slugify(item.subject);

        if (typeof item.slug_subject_type != 'undefined' && item.slug_subject_type.length > 0) {
            slug_subject_type = item.slug_subject_type;
        } else {
            if (typeof item.subject_typeid != 'undefined' && item.subject_typeid.length > 0) {
                switch (item.subject_typeid) {
                    case SUBJECT_TYPE.wtb:
                        slug_subject_type = 'wtb';
                        break;
                    default:
                        slug_subject_type = 'wts';
                        break;
                }
            } else {
                slug_subject_type = 'wts';
            }
        }

        if (item.slug_cat_1 && item.slug_cat_1 !== 'empty' && item.slug_cat_1 !== '') {
            cat_tree += "/" + item.slug_cat_1;
            if (item.slug_cat_2 && item.slug_cat_2 !== 'empty' && item.slug_cat_2 !== '' && item.slug_cat_2 !== item.slug_cat_1) {
                cat_tree += "/" + item.slug_cat_2;
                if (item.slug_cat_3 && item.slug_cat_3 !== 'empty' && item.slug_cat_3 !== '' && item.slug_cat_3 !== item.slug_cat_2) {
                    cat_tree += "/" + item.slug_cat_3;
                }
            } else if (item.slug_cat_2 && item.slug_cat_2 !== 'empty' && item.slug_cat_2 !== '') {
                if (item.slug_cat_3 && item.slug_cat_3 !== 'empty' && item.slug_cat_3 !== '' && item.slug_cat_3 !== item.slug_cat_2) {
                    cat_tree += "/" + item.slug_cat_3;
                }
            }
        }

        /**
         if (item.cat3 != null && item.cat3 !== "" && item.cat3 !== "empty") {
            cat_tree += "/" + item.slug_cat_3;
        } else if (item.cat2 != null && item.cat2 !== "") {
            cat_tree += "/" + item.slug_cat_2;
        } else if (item.cat1 != null && item.cat1 !== "") {
            cat_tree += "/" + item.slug_cat_1;
        }
         */

        if (item.manu_id !== 0 && item.manu_id !== -1 && item.manufacturer_name && item.manufacturer_name.length > 0) {
            if (item.manu_id !== -2) {
                cat_tree += "/" + item.slug_manufacturer;
            } else {
                cat_tree += "/mixed-lot";
            }
        } else {
            if (item.manu_id === -1) {
                cat_tree += "/any";
            } else if (item.manu_id === -2) {
                cat_tree += "/mixed-lot";
            }
        }

        if (typeof item.slug_model_type != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_model_type.length > 0 && item.slug_model.length > 0) {
            modal_slug_string = item.slug_model_type;
            if (modal_slug_string.includes(item.slug_model) === false) {
                found_series_slug_in_model_slug = 0;
            } else {
                found_series_slug_in_model_slug = 1;
            }
        }

        if (typeof item.slug_brand != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_brand.length > 0 && item.slug_model.length > 0) {
            series_slug_string = item.slug_model;
            if (series_slug_string.includes(item.slug_brand) === false) {
                found_brand_slug_in_series_slug = 0;
            } else {
                found_brand_slug_in_series_slug = 1;
            }
        }

        if (typeof item.slug_brand != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_model.length > 0 && item.slug_brand.length > 0) {
            found_brand_series_model_slug = 1;

            if (found_brand_slug_in_series_slug === 0) {
                if (found_series_slug_in_model_slug === 0) {
                    if (typeof item.slug_model_type != 'undefined' && item.slug_model_type.length > 0) {
                        cat_tree += "/" + item.slug_brand + "/" + item.slug_model + "/" + item.slug_model_type;
                    } else {
                        cat_tree += "/" + item.slug_brand + "/" + item.slug_model;
                    }
                } else {
                    cat_tree += "/" + item.slug_brand + "/" + item.slug_model;
                }
            } else {
                if (found_series_slug_in_model_slug === 0) {
                    if (typeof item.slug_model_type != 'undefined' && item.slug_model_type.length > 0) {
                        cat_tree += "/" + item.slug_model + "/" + item.slug_model_type;
                    } else {
                        cat_tree += "/" + item.slug_model;
                    }
                } else {
                    cat_tree += "/" + item.slug_model + "/" + item.slug_model_type;
                }
            }
        }

        if (found_brand_series_model_slug === 0) {
            if (typeof item.slug_brand != 'undefined' && item.slug_brand.length > 0) {
                cat_tree += "/" + item.slug_brand;
            }
        }

        var image_path = "";
        if (item.hasOwnProperty('image')) {
            image_path = item.image;
        } else if (item.hasOwnProperty('cat_3_image') && item.cat_3_image != null) {
            image_path = item.cat_3_image;
        } else if (item.hasOwnProperty('cat_2_image') && item.cat_2_image != null) {
            image_path = item.cat_2_image;
        } else if (item.hasOwnProperty('cat_1_image') && item.cat_1_image != null) {
            image_path = item.cat_1_image;
        }

        var image_alt = "";
        if (item.subject_typeid === SUBJECT_TYPE.wtb) {
            image_alt += "WTB: " + framework.strip(item.subject);
        } else {
            image_alt += "WTS: " + framework.strip(item.subject);
        }

        var subject = framework.strip(item.subject);
        var message = addEllipsis(_data.clean(framework.strip(item.description)), 200);

        var listing_template = Listings.__getTemplate('listing_template');
        var company_template = Listings.__getTemplate('company_template');

        let render_data = {
            gid: gid,
            isFull: Listings.listingStyle === LISTING_STYLES.full,
            isLine: Listings.listingStyle === LISTING_STYLES.line,
            isGrid: Listings.listingStyle === LISTING_STYLES.grid,
            isMobile: Listings.shouldShowMobileListings(container),
            isWTB: item.subject_typeid === SUBJECT_TYPE.wtb,
            count: typeof count !== "undefined" ? count : 1,
            listingUrl: typeof item.listing_link_to !== "undefined" ? item.listing_link_to : getListingUrl(item, slug_subject_type, cat_tree, messageSubject),
            subject: subject,
            subject_typeid: item.subject_typeid,
            subject_prefix: (item.subject_typeid === SUBJECT_TYPE.wtb ? 'WTB: ' : 'WTS: '),
            subject_type_class: (item.subject_typeid === SUBJECT_TYPE.wtb ? 'wtb' : 'wts'),
            message: message,
            expired: item.is_expired,
            allowedOffers: item.is_allowed_offers,
            allowedOrders: item.is_allowed_orders,
            buy_now_listing: item.buy_now_listing,
            isExpired: item.is_expired,
            isRegular: item.premium_id === LISTING_PREMIUM_TYPE.regular,
            isPremium: item.premium_id === LISTING_PREMIUM_TYPE.premium,
            isSponsored: item.premium_id === LISTING_PREMIUM_TYPE.sponsored,
            isSponsoredKeepOnTop: item.premium_id === LISTING_PREMIUM_TYPE.sponsoredTop,
            isMemberListing: _data._show_member_listings,
            isMembersOnly: !item.displays_to_non_members && !_data._show_member_listings,
            hasOriginalImage: item.hasOwnProperty('image'),
            shouldShowPopover: _data.use_display_name_popovers,
            shouldShowCompany: false,
            shouldBeGroup: false,
            self: is_logged_in && user.USERID.toString() === item.userid.toString(),
            is_logged_in: is_logged_in,
            hasReply: parsedItem.replyCount > 0,
            replyCount: parsedItem.replyCount,
            hasPrice: item.price != null && parseFloat(item.price) > 0.00,
            isLot: parseInt(item.target_price_typeid[0]) === TARGET_PRICE_TYPE.lot,
            hasQuantity: item.quantity != null && parseInt(item.quantity) > 0,
            hasCondition: item.condition != null && item.condition !== "",
            hasUsername: item.hasOwnProperty('username'),
            userId: item.userid,
            companyid: item.companyid,
            companyName: item.company_name,
            // company_logo: item.company_logo,
            member_since: moment(item.member_since).format('YYYY'),
            // city: item.city,
            userName: item.username,
            authorName: item.username ? item.username : item.company_name,
            credentials: [],
            hasCredentials: false,
            shouldShowDate: false,
            replied: is_logged_in && parsedItem.hasReplied,
            replyCountText: tradeloop.pluralize(_data.replyCount(item.tradefeed_id), 'reply', 'replies'),
            messageCenterUrl: tradeloop.messageCenterUrl(item.tradefeed_id, _data.replyCount(item.tradefeed_id)),
            messageReplyUrl: tradeloop.messageReplyUrl(_data.replyId(item.tradefeed_id)['message_id']),
            condition: item.condition,
            // manifest: item.manifest,
            // isNegotiable: item.isNegotiable,
            isEmbedded: true,
            formatted_string: function() {
                return function() {
                    return _data.get_formatted_string(item, false);
                }
            },
            itemCountry: function() {
                return function() {
                    return getItemShippingCountry(item);
                }
            },
            price: function() {
                return function() {
                    return getPriceHTML(item);
                }
            },
            quantity: function() {
                return function() {
                    return getQuantityHTML(item);
                }
            },
            priceQuantity: function() {
                return function() {
                    return getPriceQuantityHTML(item);
                }
            },
            conditionDescription: function() {
                return function() {
                    return clean(item.condition_description);
                }
            },
            preCondition: function() {
                return function() {
                    if (item.condition === 'Other') {
                        return 'Condition: ';
                    }
                }
            },
            typeLabel: function() {
                return function() {
                    if (render_data.isSponsored || render_data.isSponsoredKeepOnTop) {
                        return '<span class="listing__type-label">Sponsored</span>';
                    } else if (render_data.isPremium) {
                        return '<span class="listing__type-label">Premium</span>';
                    }
                }
            },
            statusLabel: function() {
                return function() {
                    if (!render_data || !render_data.is_logged_in) {
                        return;
                    }

                    if (render_data.self || render_data.replied) {
                        let result = '<span class="listing__status-label">';

                        if (render_data.self) {
                            result += 'your listing';
                        }

                        const repliesLabel = getRepliesLabelHTML(render_data);
                        result += !!repliesLabel ? repliesLabel : '';

                        return result + '</span>';
                    }
                }
            }
        };


        if (typeof item.listing_link_to !== "undefined") {
            if (item.listing_link_to === 'display_none') {
                delete render_data['listingUrl'];
            }
        }

        if(render_data.expired) {
            render_data.isRegular = true;
            render_data.isPremium = false;
            render_data.isSponsored = false;
            render_data.isSponsoredKeepOnTop = false;
        }

        render_data.isExpired = false; //render_data.expired;
        render_data.isFirm = render_data.hasPrice && render_data.allowedOrders && !render_data.allowedOffers && !render_data.expired;
        render_data.isNegotiable = render_data.hasPrice && render_data.allowedOffers && !render_data.expired;

        render_data.shouldShowLabel = render_data.isSponsored || render_data.isSponsoredKeepOnTop;
        render_data.label = 'view company info';

        // WTBs are not lots - and value 2 is not a lot
        if (render_data.isWTB || render_data.isLot === 2) {
            render_data.isLot = 0;
        }

        render_data.hasStatusLabel = !!render_data.statusLabel()();
        render_data.listingClasses = Listings.__getListingClasses(render_data);
        render_data.shouldShowCompany = render_data.isSponsored || render_data.isSponsoredKeepOnTop;

        if(render_data.shouldShowPopover){
            render_data.shouldShowAuthor = render_data.isMemberListing;
            render_data.shouldShowExtra = render_data.shouldShowAuthor || (render_data.is_logged_in && (render_data.self || render_data.replied));
        } else {
            render_data.shouldShowAuthor = false;
            render_data.shouldShowExtra = false;
        }

        /* if listing created less then 24h ago */
        if (((new Date() - new Date(item.sorting_datime * 1000)) / 1000 / 3600) < 24) {
            render_data.shouldShowDate = true;
            render_data.fromNow = moment.tz(item.sorting_datime, "America/New_York").fromNow();
            render_data.formattedTimestamp = moment.tz(item.sorting_datime, "America/New_York").format('MMM D, YYYY') + ' at ' + moment.tz(item.logdatetime, "America/New_York").format('hh:mm A');
        }

        const rendered = Mustache.render(listing_template, render_data);
        container.append(rendered);

        /** add or remove the image if we have it 
         * trades listings don't have images and they are showing a blank space on the transaction pages
        */
        if(image_path.length) {
            _data.load_image(gid, image_path, image_alt);
        } else {
            $('#item-' + gid + ' .listing__image').remove();
        }

        _data.post_render(gid, item);

        if (render_data.isSponsored || render_data.isSponsoredKeepOnTop) {
            Listings.__addCompanyInfo(render_data, item.subject_typeid, function(data) {
                const rendered = Mustache.render(company_template, data);
                container.find('.listing__company--' + data.companyid).html(rendered);
            });
        }

        if (!exports.idsToLoad[item.userid]) {
            exports.idsToLoad[item.userid] = [];
            exports.idsToLoad[item.userid].push(item.subject_typeid);
        } else {
            if (exports.idsToLoad[item.userid].indexOf(item.subject_typeid) === -1) {
                exports.idsToLoad[item.userid].push(item.subject_typeid);
            }
        }

        if (typeof callback == 'function') {
            callback();
        }
    }

    return exports;
})();
