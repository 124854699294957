import './scss/index.scss';

import '../../../shared/data';

import {FilterDrawer_v2} from "../../../shared/js/FilterDrawer_v2";
import {Search} from "../../../shared/js/Search";
import {CategoryPlates} from "../../../shared/js/CategoryPlates";
import {ManuPlates} from "../../../shared/js/ManuPlates";
import {Listings} from "../../../shared/js/Listings";
import {TradesCountBox_v2} from "../../../shared/js/TradesCountBox_v2";


/**
 * Adding SOLR for debugging on live environment.
 */
import * as Solr from "../../../shared/js/Solr";
window.Solr = Solr;

$(document).ready(function() {

    TradesCountBox_v2.set({
        tableTitle: 'In Stock Inventory',
        showManuBoxes: false,
        filters: $.extend({}, window.init_filters),
        container: $('#trades_box_container'),
        theme: 'white',
        showLoading: true,
    }).init();

    _data.refresh(function() {
        Listings.init();
        Listings.getListings(() => {
            FilterDrawer_v2.init({
                core: 'listing'
            });
        });
    }, false);

    Search.init({
        autocomplete: true,
        category_selector2: true,
        container: $('#new_search_module')
    }, function() {
        $('.header-search').show();
    });

    CategoryPlates.init();
    ManuPlates.init();

    /*StatsTable.populate({
        filters: $.extend(true, {}, window.init_filters),
        group_by: CountsTable.data.solr_field,
        group_title: CountsTable.data.group_by,
        container: $('#stats-table')
    });*/
});
