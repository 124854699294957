import * as Mustache from 'mustache';
import {addCommasToNumber, doTextHighlight, url} from "../utils";
import DataTables from "datatables.net-bs";
import "datatables.net-responsive-bs";
import * as Solr from './Solr';
import {SUBJECT_TYPE, TARGET_PRICE_TYPE} from "../../modules/members/listings/create/js/constants";

export const TradesCountBox_v2 = (function () {
    const SHOW_ROWS_LIMIT = 5;
    const UNSPECIFIED_MANU_NAME = '(unspecified)';
    const OPENED_PANEL_CLASS = 'tl-expand-panel--opened';

    const exports = {};

    exports.data = {
        mode: null,
        allowHiding: true,
        allowRemove: false,
        allowUpgradeMessages: false,
        showManuBoxes: false,
        showBackToManusLink: false,
        showLoading: false,
        showShowMore: true,
        showSeller: false,
        useFaceting: true,
        tableTitle: '',
        filters: {},
        container: null,
        rows: [],
        buckets: [],
        catalogIds: [],
        manufacturers: [],
        sellerIds: [],
        totalInStock: 0,
        rowsPerPage: 100,
    };

    exports._getTradesSingleUrl = function(trades_id) {
        return '/trades/single?id=' + trades_id;
    };

    exports._getTradesSearchUrl = function(hPart, manu) {
        return tradeloop.tradesSearchUrl(hPart, hPart, manu[1] ? manu[1] : null);
    };

    exports.hide = function () {
        if (exports.data.container && exports.data.allowHiding) exports.data.container.html('');
    };

    exports.removeBox = function () {
        if (exports.data.container && exports.data.allowRemove) {
            exports.data.container.remove();
        } else {

        }
    };

    exports.set = function (data) {
        exports.data = $.extend(exports.data, data);

        return exports;
    };

    exports._showText = function(extraHTML) {
        exports.data.container.html(
            '<div class="clearfix"></div>' +
            '<div id="available-in-stock-listing" class="catalog-section">' +
                '<div class="catalog-section__title">' +
                    '<div class="catalog-section__title-left">' +
                        '<h2>' + exports.data.tableTitle +
                            (exports.data.allowUpgradeMessages ? '' +
                                '<button class="tl-btn-link tl-btn-link--catalog" onclick="tradeloop.doModalUppathOpen({link_action: window.tbtUploadInv});">' +
                                    '<span>+</span><span>Upload Your Inventory</span>' +
                                '</button>' : '') +
                        '</h2>' +
                    '</div>' +
                '</div>' + extraHTML +
            '</div>'
        );
    }

    exports.showError = function(errorText) {
        exports._showText(
            '<div class="listings-empty no-items">' +
            '    <span class="listings-empty__text">' + errorText + '</span>' +
            '</div>'
        );
    }

    exports.showLoading = function() {
        exports._showText(
            '<div class="listings-loading item-loading">' +
            '    <i class="fas fa-spin fa-cog"></i>' +
            '    <span class="listings-loading__text">Loading</span>' +
            '</div>'
        );
    }

    exports.showEmptyMessage = function() {
        exports._showText(
            '<div class="listings-empty no-items">' +
            '    <span class="listings-empty__text">No inventory found. Try changing your search term, or try using different filters on the left.</span>' +
            '</div>'
        );
    }

    exports.hasSearchQuery = function() {
        return _data.filters.hasOwnProperty('search') && _data.filters.search && _data.filters.search !== ''
    };

    exports.showAddToWikiBox = function() {
        var template = $('#trades_box_add_to_wiki_template').html();
        Mustache.parse(template);   // optional, speeds up future uses

        const render_data = {
            tableTitle: exports.data.tableTitle,
            search_string: _data.filters.search,
            search_string_short:  _data.filters.search.length > 15 ? _data.filters.search.slice(0, 14) + '...' : _data.filters.search,
            company_name: window.company_name ? window.company_name : '',
            company_profile_link: window.company_profile_link ? window.company_profile_link : '',
            add_to_wiki_link:  window.add_to_wiki_link + _data.filters.search,
        };

        exports.data.container.html(Mustache.render(template, render_data));
    };


    exports.populate = function (data) {
        exports.data = $.extend(exports.data, data);

        if (exports.data.showLoading) {
            exports.showLoading();
        }

        exports.data.showSeller = _data._show_member_listings && exports.data.mode !== 'profile';

        var filter_str = _data.parseFilterParams(exports.data.filters, 'part');
        var qpart = '';
        var search = '*:*';

        //console.log(exports.data);

        if (exports.hasSearchQuery()) {
            qpart = _data.filters.search.replace(/[^A-Za-z0-9]/gi, '');
            var qorq = "(*" + qpart + "* OR " + qpart + ")";
            search = '(part_clean:' + qorq + '^10 OR description:' + qorq + '^3 )';
            var words =  _data.filters.search.split(' ');
            var search_str = "(";
            if(words) {
                words.forEach((w, i)=>{
                    const regex = /((?:^|[^\\])(?:\\{2})*)"/gm;
                    const subst = `$1\\"`;
                    w = w.replace(regex, subst);
                    search_str += (i===0?"":" AND ")+" combinedSearch:"+w;
                })
            }
            search += ' OR '+search_str+')^1.1';
        } else {
            search = '*:*';
        }

        if (_data.filters.hasOwnProperty('search') && _data.filters.search === '*') {
            search = '*:*';
        }

        exports.data.rows = [];
        exports.data.manufacturers = [];
        exports.data.sellerIds = [];

        let facetingJson = {
            parts: {
                type: "terms",
                field: "manu_part",
                limit: exports.data.rowsPerPage,
                sort: {"min_price": "asc"},
                facet: {
                    "max_price": "max(price)",
                    "min_price": "min(retail_price_aggregates)",
                    "quantity": "sum(quantity)",
                    "num_sellers": "unique(userid)"
                }
            },
            manus: {
                type: "terms",
                field: "manu_id",
                limit: 100
            }
        };

        let rows = 0;
        let startFrom = 0;
        if (!exports.data.useFaceting || exports.data.showManuBoxes) {
            delete facetingJson.parts;
        }

        if (!exports.data.showManuBoxes || _data.filters.hasOwnProperty('manufacturer_ids') || exports.hasSearchQuery()) {
            delete facetingJson.manus;
            rows = exports.data.rowsPerPage;
            startFrom = (_data.filters.page - 1) * exports.data.rowsPerPage;

            /*if (!_data._show_member_listings) {
                filter_str.push("displays_to_non_members:true");
            }*/
        }

        exports.getData = {
            search: search,
            start: startFrom,
            length: rows,
            rows: rows,
            filters: filter_str,
            facetjson: JSON.stringify(facetingJson),
            sort: ['score:DESC','price:DESC'],
            _token: $('input[name="_token"]:first').val(),
            tlpxtoken: window.tlpxtoken,
            'x-sender': 'js'
        };

        if (facetingJson.manus) {
            let query = Solr.Model.searchRaw(search);
            if(filter_str) {
                filter_str.forEach((str)=>{
                    query.whereRaw(str);
                });
            }
            query.facet(facetingJson)
                .orderBy('score','DESC')
                .orderBy('price','DESC')
                .get(rows, _data.filters.page)
                .then((r)=>{
                    if (r.hasOwnProperty('facets') && r.facets.hasOwnProperty('manus') && r.facets.manus.buckets.length > 0) {
                        exports.loadManus(r, filter_str);
                    } else if (r.response.docs.length === 0) {
                        if (exports.data.allowRemove) {
                            exports.removeBox();
                        } else {
                            exports.showEmptyMessage();
                        }
                    }
                },()=>{
                    exports.showError('An error occurred. Please, try again later.');
                })
            /*
            framework.get(url("api/search/listings"), exports.getData, function (r) {
                if (r.hasOwnProperty('facets') && r.facets.hasOwnProperty('manus') && r.facets.manus.buckets.length > 0) {
                    exports.loadManus(r, filter_str);
                } else if (r.response.docs.length === 0) {
                    if (exports.data.allowRemove) {
                        exports.removeBox();
                    } else {
                        exports.showEmptyMessage();
                    }
                }
            }, function(error) {
                exports.showError('An error occurred. Please, try again later.');
                console.error(error);
            });

             */

        } else {

            // drawing table html
            var trades_box_template = $('#trades_box_template').html();
            Mustache.parse(trades_box_template);   // optional, speeds up future uses
            var render_data = {
                tableTitle: exports.data.tableTitle,
                tableWrapperExtraClasses: exports.data.theme === 'white' ? 'tl-expand-panel--gray' : 'tl-expand-panel--white',
                showShowMore: exports.data.showShowMore,
                showUpgradeMessages: exports.data.allowUpgradeMessages,
                showBackLink: exports.data.showManuBoxes && exports.data.showBackToManusLink && _data.filters.hasOwnProperty('manufacturer_ids'),
                showBamBox: !_data._show_member_listings
            }
            var rendered = Mustache.render(trades_box_template, render_data);
            exports.data.container.html(rendered);

            // adding handlers for template
            $('#show_manus_button').click(function() {
                delete _data.filters.manufacturer_ids;
                delete _data.filters.manufacturer_name;

                exports.populate({
                    filters: _data.filters
                });

                return false;
            });

            if (exports.data.showShowMore) {
                exports.data.container.find('.tl-expand-panel__button').click(event => {
                    event.preventDefault();

                    const $me = $(event.target);
                    const $panel = $me.parents('.tl-expand-panel');

                    if ($panel.hasClass(OPENED_PANEL_CLASS)) {
                        $panel.removeClass(OPENED_PANEL_CLASS);
                        $me.html('Show more <span class="fas fa-chevron-down" aria-hidden="true"></span>');
                    } else {
                        $panel.addClass(OPENED_PANEL_CLASS);
                        $me.html('Show less <span class="fas fa-chevron-up" aria-hidden="true"></span>');
                    }
                });
            }

            // initializing data tables plugin
            if ($.fn.DataTable.isDataTable('#parts-table')) {
                $('#parts-table').DataTable().destroy(false);
            }

            var table = new DataTables('#parts-table', {
                    "language": {
                        "emptyTable":     "No parts in stock.",
                        "info":           "Showing _START_ to _END_ entries",
                        "infoEmpty":      "0 entries",
                        "infoFiltered":   "",
                    },
                    responsive: framework.screen().width < 1200,
                    pageLength: exports.data.rowsPerPage,
                    serverSide: true,
                    processing: true,
                    dom: '<tip>',
                    ajax: {
                        data: function(pluginData) {
                            if (table !== undefined) {
                                const order = table.order();
                                const fieldName = table.column(order[0][0]).dataSrc();
                                exports.getData.sort = [fieldName + ':' + order[0][1].toUpperCase()];
                            }
                            return exports.getData;
                        },
                        url: url("api/search/listings"),
                        dataSrc: (r) => {
                            if (r.response.docs.length > 0) {
                                return r.response.docs;
                            } else {
                                return [];
                            }
                        },
                        dataType: 'json',
                        dataFilter: function(r) {
                            var json = jQuery.parseJSON(r);
                            json.recordsTotal = json.facets.count;
                            /*json.recordsFiltered = json.facets.count;
                            json.data = json.response.docs;*/

                            if(!_data._show_member_listings) {
                                // filter out members-only listings
                                json.data = json.response.docs.filter((item, index) => {
                                    return item.displays_to_non_members.toString() === "true"
                                });
                                json.response.docs = json.data;

                                // update items count
                                json.recordsFiltered = json.data.length;

                                // show/hide bam box, cover 'no records' label if all members-only parts hidden
                                if(json.recordsFiltered !== json.recordsTotal) {
                                    $('#tb_bam_box')
                                        .css('margin-top', json.recordsFiltered === 0 ? '-35px' : '-6px')
                                        .show();

                                } else {
                                    $('#tb_bam_box').hide();
                                }
                            } else {
                                json.recordsFiltered = json.facets.count;
                                json.data = json.response.docs;
                            }

                            return JSON.stringify(json);
                        },
                        error: function (xhr, error, code) {
                            console.error('Trades Table error:', code);
                            exports.showError('An error occurred. Please, try again later.');
                        }
                    },
                    columnDefs: [{
                        defaultContent: "-",
                        targets: "_all"
                    }],
                    columns: [
                        // vendor
                        {
                            className: 'text-nowrap',
                            visible: exports.data.mode !== 'profile',
                            data: 'company_name',
                            "render": function(data, type, item, meta) {
                                if (exports.data.sellerIds.indexOf(item.userid) === -1) {
                                    exports.data.sellerIds.push(item.userid);
                                }
                                const content = exports.data.showSeller ? item.company_name : 'Tradeloop Member';
                                return '<span data-seller-id="' + item.userid + '">' + content + '</span>';
                            }
                        },
                        // country
                        {
                            className: 'text-center',
                            visible: exports.data.mode !== 'profile',
                            data: 'company_country',
                            "render": function(data, type, item, meta) {
                                return `<span class="flag flag-${item.company_country_code}" data-toggle="tooltip" title="${item.company_country}"></span>`;
                            }
                        },
                        // part #
                        {
                            className: '',
                            data: 'part_number',
                            "render": function(data, type, item, meta) {
                                let resultHtml = '<span class="fs-14">';
                                if (window.is_logged_in) {
                                    resultHtml += '<a href="' + exports._getTradesSingleUrl(item.trades_id) + '" data-num-sellers="' + item.num_sellers + '" rel="nofollow,noindex">' + item.part_number + '</a>';
                                } else {
                                    resultHtml += '<a onclick="tradeloop.modal(\'register\', null,{ type: \'reply\'},\'trades-box/left-bold-link/part-number\');" class="btn-contact-member" rel="nofollow,noindex">' + item.part_number + '</a>';
                                }
                                resultHtml += '</span>';
                                return resultHtml;
                            }
                        },
                        // manufacturer
                        {
                            className: '',
                            data: 'manufacturer_name',
                            "render": function(data, type, item, meta) {
                                return item.manufacturer_name;
                            }
                        },
                        // description
                        {
                            className: '',
                            data: 'description',
                            "render": function(data, type, item, meta) {
                                return `<div style="min-width: 200px;">${item.description}</div>`;
                            }
                        },
                        // condition
                        {
                            data: 'condition',
                            "render": function(data, type, item, meta) {
                                return item.condition;
                            }
                        },
                        // quantity
                        {
                            className: 'text-center',
                            data: 'quantity',
                            "render": function(data, type, item, meta) {
                                return item.quantity > 25 ? '>25' : item.quantity;
                            }
                        },
                        // price
                        {
                            className: 'text-center price-r-b',
                            data: 'price',
                            "render": function(data, type, item, meta) {
                                let min_price = '';

                                if (!item.hasOwnProperty('price') || item.price === null || item.price === 0 || item.price === 0.00) {
                                    min_price = '-';
                                } else {
                                    min_price = '$' + addCommasToNumber(parseFloat(item.price).toFixed(2));
                                }

                                return min_price;
                            }
                        },
                    ]
                });

            table
                /*.on('xhr.dt', function(e, settings, json, xhr) {
                    console.log('xhr');
                })*/
                .on('page.dt', function() {
                    const info = table.page.info();
                    exports.getData.start = info.page * exports.data.rowsPerPage;
                })
                .on('draw.dt', function() {
                    if (table.data().length === 0 && _data._show_member_listings && exports.hasSearchQuery()) {
                        exports.showAddToWikiBox();
                        return;
                    }

                    if (table.data().length === 0 && exports.data.allowRemove) {
                        exports.removeBox();
                        return;
                    }

                    if (!_data._show_member_listings && $('#tb_bam_box').length > 0) {
                        $('#tb_bam_box').insertAfter('#parts-table').removeClass('hidden');
                    } else if (table.data().length === 0) {
                        exports.showEmptyMessage();
                        return;
                    }

                    if (table.data().length <= SHOW_ROWS_LIMIT && exports.data.showShowMore) {
                        $('#tb_table_wrapper').addClass('tl-expand-panel--disabled');
                    } else {
                        $('#tb_table_wrapper').removeClass('tl-expand-panel--disabled');
                    }

                    if (exports.hasSearchQuery()) {
                        doTextHighlight('parts-table', _data.filters.search);
                    }

                    $('#parts-table [data-toggle="tooltip"]').tooltip();

                    exports.loadSellersInfo();
                })
                .on('responsive-display.dt', function(e, datatable, row, showHide, update) {
                    if (showHide) {
                        $(row.node()).next('.child').find('[data-toggle="tooltip"]').tooltip();
                    }
                });
        }
    };


    exports.loadManus = function(r, filter_str) {
        const ids = [];
        // console.log("Loading Manufacturers");

        r.facets.manus.buckets.forEach(function (item, i) {
            ids.push(item.val);

            exports.data.manufacturers.push({
                id: item.val,
                count: addCommasToNumber(item.count),
                countText: item.count === 1 ? 'item' : 'items'
            });
        });

        const data = {
            search: 'manu_id:(' + ids.join(' OR ') + ')',
            start: 0,
            rows: 9999,
            filters: filter_str,
            group: ['manu_id'],
            fields:['manufacturer_name']
        };

        let query = Solr.Model.searchRaw('manu_id:(' + ids.join(' OR ') + ')');
        if(filter_str) {
            filter_str.forEach((str)=>{
                query.whereRaw(str);
            });
        }
        query.groupBy('manu_id')
            .select('manufacturer_name')
            .get(9999,1).then((response)=>{
                // console.log(response);
                response.grouped.manu_id.groups.forEach((item, i) => {
                    const index = exports.data.manufacturers.findIndex(manu => manu.id === item.groupValue);

                    if (parseInt(item.groupValue) === 0 && index > -1) {
                        exports.data.manufacturers[index].title = UNSPECIFIED_MANU_NAME;
                        exports.data.manufacturers.push(exports.data.manufacturers[index]);
                        exports.data.manufacturers.splice(index, 1);
                    } else if (index > -1) {
                        exports.data.manufacturers[index].title = item.doclist.docs[0].manufacturer_name;
                    }
                });

                exports.drawManus();
            },()=>{
                exports.showError('An error occurred. Please, try again later.');
            });
        /*
        framework.get(url("api/search/listings"), data, function (response) {
            response.grouped.manu_id.groups.forEach((item, i) => {
                const index = exports.data.manufacturers.findIndex(manu => manu.id === item.groupValue);

                if (parseInt(item.groupValue) === 0 && index > -1) {
                    exports.data.manufacturers[index].title = UNSPECIFIED_MANU_NAME;
                    exports.data.manufacturers.push(exports.data.manufacturers[index]);
                    exports.data.manufacturers.splice(index, 1);
                } else if (index > -1) {
                    exports.data.manufacturers[index].title = item.doclist.docs[0].manufacturer_name;
                }
            });

            exports.drawManus();
        }, function(error) {
            exports.showError('An error occurred. Please, try again later.');
            console.error(error);
        });

         */
    }


    function getThemeClass(theme) {
        switch (theme) {
            case 'white':
                return 'tl-manu-cards-wrapper--white';
        }

        return '';
    }


    exports.drawManus = function(callback) {
        var template = $('#trades_box_manus_template').html();
        Mustache.parse(template);   // optional, speeds up future uses

        var render_data = {
            tableTitle: exports.data.tableTitle,
            manufacturers: [],
            other_manufacturers: [],
            hasOtherManufacturers: false,
            is_logged_in: is_logged_in,
            showUpgradeMessages: exports.data.allowUpgradeMessages,
            themeClass: getThemeClass(exports.data.theme),
        }

        if (exports.data.showManuBoxes && !exports.hasSearchQuery()) {
            if (!_data.filters.hasOwnProperty('manufacturer_ids')) {
                render_data.rows = [];
                render_data.hasRows = false;

                if (exports.data.manufacturers.length > 7) {
                    render_data.manufacturers = exports.data.manufacturers.slice(0, 7);
                    render_data.manufacturers.push({
                        title: '+ more'
                    });
                    render_data.other_manufacturers = exports.data.manufacturers.slice(7);
                    render_data.other_manufacturers.sort((a, b) => {
                        if (a.title < b.title) {
                            return -1;
                        }
                        if (a.title > b.title) {
                            return 1;
                        }
                        return 0;
                    });
                    if (render_data.other_manufacturers[0].title === UNSPECIFIED_MANU_NAME) {
                        render_data.other_manufacturers.push(render_data.other_manufacturers[0]);
                        render_data.other_manufacturers.splice(0, 1);
                    }
                    if (!_data._show_member_listings) {
                        render_data.other_manufacturers.push({
                            show_all: true
                        });
                    }
                    render_data.other_manufacturers.push({
                        collapse: true
                    });
                    render_data.hasOtherManufacturers = true;
                } else {
                    render_data.manufacturers = exports.data.manufacturers;
                }
            } else {
                render_data.manufacturers = [];
                render_data.other_manufacturers = [];
            }
        }

        var rendered = Mustache.render(template, render_data);
        exports.data.container.html(rendered);

        setTimeout(function () {
            $('[data-manu-id]').click(function() {
                _data.filters.manufacturer_ids = [this.getAttribute('data-manu-id')];
                _data.filters.manufacturer_name = this.getAttribute('data-manu-name');

                if (exports.data.showManuBoxes && exports.data.showBackToManusLink) {
                    exports.populate({
                        filters: _data.filters
                    });
                } else {
                    document.dispatchEvent(new CustomEvent('filter.refresh'));
                }

                return false;
            });

            if (callback && typeof callback === "function") {
                callback();
            }
        }, 500);
    };

    exports.loadSellersInfo = function() {
        exports.data.sellerIds.forEach(function(id, index) {
            var requestData = {
                "usid":       id,
                "tlpxtoken":  window.tlpxtoken,
                "x-sender":   "js",
                "_token":     $('input[name="_token"]:first').val(),
            };
            framework.get(url('api/user_details'), requestData,function (r) {
                if (r.details && r.details[0]) {
                    const data = r.details[0];
                    const $sellerNameEl = $('[data-seller-id="' + id + '"]');
                    let resultHtml = '';

                    if (!exports.data.showSeller && parseInt(data.status) !== 355 && parseInt(data.status) !== 360) {
                        resultHtml += '' +
                            '<a href="javascript:void(0);" class="tl-dashed tl-text--initial" ' +
                            'data-toggle="popover" data-popover-type="bam">' +
                            'Tradeloop Member' +
                            '</a>';
                    } else {
                        if (parseInt(data.status) === 355 || parseInt(data.status) === 360) {
                            resultHtml += '' +
                                '<a href="javascript:void(0);" class="pro-member-star" ' +
                                'data-toggle="popover" data-popover-type="pro">' +
                                '<i class="fas fa-star"></i>' +
                                '</a>';
                        }

                        if ((parseInt(data.membership_approval_settingsID) === 1 && parseInt(data.non_member) === 0) ||
                            (parseInt(data.membership_approval_settingsID) === 3 && parseInt(data.non_member) === 0)) {
                            resultHtml += '' +
                                '<a href="javascript:void(0);" class="tl-member-icon" ' +
                                'data-toggle="popover" data-popover-type="member" data-cid="' + data.companyid + '">' +
                                '<img width="16" height="16" src="/images/tl-cert/tradeloop-cert-small-1.png" ' +
                                'style="vertical-align:-3px;">' +
                                '</a>';
                        }

                        resultHtml += data.fname + ' ' + data.lname + ' at <br class="hidden-lg">' + data.company_long_name;
                    }

                    $sellerNameEl.html(resultHtml);
                    $sellerNameEl.find('[data-toggle="popover"]').each((index, item) => {
                        let content = '';
                        const type = item.getAttribute('data-popover-type');

                        switch (type) {
                            case 'pro':
                                content = '' +
                                    '<div class="padding-5 text-center text-nowrap">' +
                                    '<strong class="fs-14">Tradeloop PRO Member</strong>';

                                if (exports.data.showSeller) {
                                    content += '<br><a href="' + window.tbtTLProUrl + '" class="tl-btn-primary tl-btn-small margin-top-5 fw-bold">Try PRO</a>';
                                }

                                content += '</div>';
                            break;

                            case 'member':
                                content = '' +
                                    '<div class="padding-top-5 text-center text-nowrap">' +
                                    '<img src="/images/tl-cert/tradeloop-cert-1.png" alt="Tradeloop Certified Member" width="120" height="41">';

                                if (exports.data.showSeller) {
                                    const url = window.tbtDDRUrl + item.getAttribute('data-cid');
                                    content += '<br>' +
                                        '<a href="' + url + '" class="dp-inline-block margin-top-10 fs-14 fw-bold">' +
                                        'Due Diligence Report' +
                                        '</a>';
                                } else {
                                    content += '' +
                                        '<div class="bam-box bam-box--trades-seller margin-top-5">' +
                                        '<div class="bam-box__action">' +
                                        '<i class="fas fa-lock tl-color--primary" style="vertical-align: 1px; margin-right: 5px;"></i>' +
                                        '<a href="' + window.tbtCertMemberBamUrl + '">Become a Member</a><br>to see Due Diligence Report' +
                                        '</div>' +
                                        '</div>';
                                }

                                content += '</div>';
                            break;

                            default:
                                content = '' +
                                    '<div class="bam-box bam-box--trades-seller text-nowrap">' +
                                    '<div class="bam-box__action">' +
                                    '<i class="fas fa-lock tl-color--primary" style="vertical-align: 1px; margin-right: 5px;"></i>' +
                                    '<a href="' + window.tbtSellerBamUrl + '">Become a Member</a><br>to see seller\'s info' +
                                    '</div>' +
                                    '</div>';
                        }

                        $(item).popover({
                            trigger: 'click',
                            placement: 'top',
                            container: '#available-in-stock-listing',
                            html: true,
                            content: content
                        });
                    });
                }
            }, function (e, data) {
                //console.error(data.error);
            });
        });
    };

    exports.init = function() {
        document.addEventListener('tradesCountBox.update', () => {
            // turning alerts into console output
            $.fn.dataTable.ext.errMode = function ( settings, helpPage, message ) {
                console.error('DataTables error:', message);
            };

            exports.populate({
                filters: _data.filters
            });
        });

        document.addEventListener('filter.changed', () => {
            // hide trades count box if selected only WTB
            if (_data.filters.hasOwnProperty('type') && _data.filters.type === SUBJECT_TYPE.wtb) {
                exports.hide();
            }

            // hide trades count box if selected only bulk lots
            if (_data.filters.hasOwnProperty('target_type') && _data.filters.target_type === TARGET_PRICE_TYPE.lot) {
                exports.hide();
            }

            if (_data.filters.hasOwnProperty('search') && _data.filters.search !== '') {
                return;
            }

            // hide trades count box if neither manufacturer nor category selected
            if (
                (!_data.filters.hasOwnProperty('manufacturer_ids') || _data.filters.manufacturer_ids.length === 0)
                && (!_data.filters.hasOwnProperty('categories') || _data.filters.categories.length === 0)
            ) {
                exports.hide();
            }
        });

        return exports;
    };

    return exports;
})();
